<template>
  <section
    class="grey lighten-3"
  >
    <div class="py-1"></div>

    <v-container class="text-center">
      <h2 class="display-2 font-weight-bold mb-3">Carrito</h2>

      <v-responsive
        class="mx-auto mb-5"
        width="56"
      >
        <v-divider class="mb-1"></v-divider>

        <v-divider></v-divider>
      </v-responsive>

      <CartSummary>
        <template slot="footer">
          <v-btn block large color="success" @click="goToPay">Pagar</v-btn>
        </template>
      </CartSummary>

    </v-container>
    <div class="py-1"></div>
  </section>
</template>

<script>

import CartSummary from '@/views/components/CartSummary.vue'

export default {
  methods: {
    goToPay () {
      const url = this.$router.resolve({ name: 'cart.summary' }).href
      window.location.assign(url)
    }
  },
  components: {
    CartSummary
  }
}
</script>
