import axios from 'axios'
import configService from '@/api/ORM/connection/config'
import { getStoreSlug } from '@/api/utils'

const instance = axios.create({
  baseURL: configService.apiUrl,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  transformRequest: [function (data, headers) {
    const storeSlug = getStoreSlug()
    if (storeSlug) {
      headers['Store-Slug'] = storeSlug
    }

    return JSON.stringify(data)
  }]
})

/*
  if (token) {
    config.headers['X-CSRF-TOKEN'] = token
  } else {
    // console.error('CSRF token not found')
  }
*/

export default instance
