<template>
  <v-main>
    <div style="margin: 10px; padding: 50px;">
      <v-alert type="success" border="top" dense outlined text>
        <h3 class="mb-3">Revisa tu correo para completar tu compra</h3>

        <h4>Compra en Proceso</h4>
      </v-alert>

      <v-alert type="info" outlined>
        <p>Si tienes dudas puedes contactarnos por Whatsapp.</p>
      </v-alert>

      <v-alert
        color="black"
        border="left"
        elevation="5"
        colored-border
        icon="mdi-cash"
      >
        <span>PAGO MANUAL POR TRANSFERENCIA U OTROS MEDIOS DE PAGO COMO NEQUI, DAVIPLATA Y MÁS</span>
        <br>
        <span>(Te enviaremos un correo con todas las intrucciones para realizar tu pago manual)</span>
        <br>
      </v-alert>

      <v-btn
        rounded
        color="primary"
        size="x-large"
        :href="$router.resolve({ name: 'portal.index' }).href"
        class="mt-5"
      >
        Volver
      </v-btn>
    </div>
  </v-main>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  methods: {
  },
  created () {
    // this.id = this.$route.query.po
  }
}
</script>
