function getStoreSlug () {
  const tokenContainer = document.head.querySelector('meta[name="store-slug"]')
  const token = tokenContainer
    ? tokenContainer.content
    : null
  return token
}

/*
function getTokenUser () {
  const tokenContainer = document.head.querySelector('meta[name="csrf-token"]')
  const token = tokenContainer
    ? tokenContainer.content
    : null
  return token
}
*/

function setStoreSlug (slug) {
  const tokenContainer = document.head.querySelector('meta[name="store-slug"]')
  tokenContainer.content = slug
}

export { getStoreSlug, setStoreSlug }
