<template>
  <v-autocomplete
    :items="items"
    :label="label"
    v-model="localValue"
    v-bind='$attrs'
    :search-input.sync="search"
    hide-no-data
    hide-selected
    :filter="fnFilter"
    :item-text="itemText"
    :item-value="itemValue"
    :return-object="false"
  ></v-autocomplete>
</template>

<script>
import SelectMixin from '@/plugins/Selects/SelectMixin.js'

export default {
  props: {
    itemText: {
      type: String,
      default: 'name'
    },
    itemValue: {
      type: String,
      default: 'code'
    }
  },
  methods: {
    loadSource () {
      this.$store.dispatch('general/loadDepartaments', this.$route.params.portalId)
        .then(departaments => {
          this.source = departaments

          this.setItems(this.source)
        })
        // .catch(error => {})
    }
  },
  mixins: [SelectMixin]
}
</script>
