<template>
  <section
    class="grey lighten-3"
  >
    <div class="py-1"></div>

    <v-container class="text-center">
      <h2 class="display-2 font-weight-bold mb-3">Contactanos</h2>

      <v-responsive
        class="mx-auto mb-5"
        width="56"
      >
        <v-divider class="mb-1"></v-divider>

        <v-divider></v-divider>
      </v-responsive>

      <v-form
        ref="contactForm"
        lazy-validation
      >
      <v-row>
          <v-col cols="12">
            <v-text-field
              flat
              label="Nombre*"
              solo
              dense
              v-model="form.name"
              :rules="nameRules"
            ></v-text-field>

            <v-text-field
              flat
              label="Email*"
              solo
              dense
              v-model="form.email"
              :rules="emailRules"
            ></v-text-field>
            <v-text-field
              flat
              label="Teléfono*"
              solo
              dense
              v-model="form.phone"
              :rules="cellphoneRules"
            ></v-text-field>
            <v-textarea
              flat
              label="Mensaje*"
              solo
              dense
              rows="3"
              v-model="form.message"
              :rules="requiredRule"
            ></v-textarea>

            <v-checkbox color="purple" v-model="form.accept_terms_and_conditions" :rules="termsAndConditionsRules">
              <template v-slot:label>
                <p>
                  ¿Aceptas el uso de tus datos personales de acuerdo a la Ley 1581 de 2012 y
                  normas relacionadas y las políticas de protección de datos personales con el
                  fin de suscribirte en nuestra base de datos con fines comerciales y de análisis?
                </p>
              </template>
            </v-checkbox>

            <a target="blank" class="my-4 d-inline-block" :href="$router.resolve({ name: 'portal.legal', query: { q: 'data_processing_policies' } }).href">Política de Tratamiento de Datos Personales*</a>

            <v-btn
              color="accent"
              block
              @click="prepareContact"
            >
              Enviar
            </v-btn>
          </v-col>

          <v-col cols="12" v-if="status.loading">
            <v-alert type="info">
              Estamos enviando tu mensaje.
            </v-alert>
          </v-col>
      </v-row>
      </v-form>
    </v-container>

    <div class="py-1"></div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { nameRules, emailRules, cellphoneRules, termsAndConditionsRules, requiredRule } from '@/utils/FormRules.js'
import LoadingMixin from '@/plugins/Common/LoadingMixin.js'
import RecaptchaMixin from '@/plugins/Common/RecaptchaMixin.js'

export default {
  data () {
    return {
      form: {
        name: '',
        email: '',
        phone: '',
        message: '',
        token: '',
        accept_terms_and_conditions: false
      },
      nameRules,
      emailRules,
      cellphoneRules,
      termsAndConditionsRules,
      requiredRule
    }
  },
  methods: {
    getData () {
      return this.form
    },
    reset () {
      this.form.name = ''
      this.form.email = ''
      this.form.phone = ''
      this.form.message = ''
      this.form.accept_terms_and_conditions = false

      this.$refs.contactForm.resetValidation()
    },
    onGetRecaptchaToken (token) {
      this.form.token = token
    },
    prepareContact () {
      const contactFormIsValid = this.$refs.contactForm.validate()
      if (!contactFormIsValid) return

      if (this.statusIsLoading()) return

      this.statusLoading()

      this.getRecaptchaToken('contact')
        .then(token => { this.sendMail() })
    },
    sendMail () {
      const data = this.getData()

      // this.statusLoading()

      this.$Models.Contact.send(data)
        .then(res => {
          this.statusLoadingComplete()
          this.showSuccessMessage()

          if (res.ok) {
            this.showSuccessMessage(res.message)
          } else {
            this.showError(res.message)
          }

          if (res.ok) {
            this.reset()
          }
        })
        .catch((err) => {
          this.statusLoadingFail()
          this.showError(err)
        })
    }
  },
  computed: {
    ...mapState('general', {
      info: state => state.info
    })
  },
  mixins: [LoadingMixin, RecaptchaMixin],
  watch: {
    info (nVal, oVal) {
      this.wathInfoSiteKey(nVal, oVal)
    }
  }
}
</script>
