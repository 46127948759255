<template>
<v-text-field
  ref='field'
  v-model='model'
  @focus='onFocus'
  @keyup='onKeyUp'
  v-bind='$attrs'
  @change='onChange'
  @blur='onBlur'
>
</v-text-field>
</template>

<script>
import { format as formatCurrency, unformat as unformatCurrency } from '@/utils/currency'

export default {
  props: {
    value: null
  },

  data () {
    return {
      numberValue: this.value,
      model: this.value,
      isMasked: true
    }
  },

  methods: {
    onFocus () {
      this.isMasked = false
      this.updateModel()
    },

    onBlur () {
      if (this.$listeners.blur) this.$listeners.blur()

      this.isMasked = true
      this.format()
    },

    onKeyUp () {
      this.updateNumberValue()
    },

    onChange () {
      if (this.$listeners.change) this.$listeners.change()
    },

    updateNumberValue () {
      this.numberValue = unformatCurrency(this.model)
    },

    updateModel () {
      if (this.numberValue === null) return
      if (this.numberValue === undefined) return

      this.model = formatCurrency(this.numberValue)
    },

    format () {
      if (this.numberValue === null) return
      if (this.numberValue === undefined) return

      this.model = formatCurrency(this.numberValue)
    }
  },

  watch: {
    numberValue (v) {
      this.$emit('input', v)
    },

    value (v) {
      this.numberValue = v

      if (!this.$refs.field.isFocused) {
        this.format()
      }
    }
  },

  created () {
    this.format()
  }
}
</script>

<style lang="scss" scoped>
</style>
