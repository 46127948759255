<template>
  <section v-if="info" id="links">
    <v-container v-if="info.show_online_booking_link || info.can_sell_gift_cards" class="text-center grey lighten-3 mt-5">
      <v-responsive
        class="mx-auto mb-5"
        width="56"
      >
        <v-divider class="mb-1"></v-divider>

        <v-divider></v-divider>
      </v-responsive>

      <v-row>
        <v-col v-if="info.show_online_booking_link" cols="12" sm="4" md="4" lg="4">
          <v-card
            width="100%"
            raised
            v-ripple
            hover
            :href="info.online_booking_link"
          >
            <v-card-text class="text-center">
              <v-icon color="black" large>mdi-calendar-check-outline</v-icon>
              <p class="title mb-0 mt-2 black--text">Agendar Cita</p>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col v-if="info.can_sell_gift_cards" cols="12" sm="4" md="4" lg="4">
          <v-card
            width="100%"
            raised
            v-ripple
            hover
            :href="$router.resolve({ name: 'portal.giftCards' }).href"
          >
            <v-card-text class="text-center">
              <v-icon color="black" large>mdi-gift</v-icon>
              <p class="title mb-0 mt-2 black--text">Tarjetas Regalo</p>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col v-if="info.show_online_booking_link || info.can_sell_gift_cards" cols="12" sm="4" md="4" lg="4">
          <v-card
            width="100%"
            raised
            v-ripple
            hover
            :href="$router.resolve({ name: 'portal.products.search' }).href"
          >
            <v-card-text class="text-center">
              <v-icon color="black" large>mdi-archive</v-icon>
              <p class="title mb-0 mt-2 black--text">Productos</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('general', {
      info: state => state.info
    })
  }
}
</script>
