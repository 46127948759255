import Vue from 'vue'
import VueI18n from 'vue-i18n'

// Localisation language list
import { locale as es } from '@/common/config/i18n/es'

Vue.use(VueI18n)

let messages = {}
messages = { ...messages, es }

// get current selected language
const lang = 'es'

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: lang, // set locale
  messages // set locale messages
})

export default i18n
