<template>
  <section
    class="grey lighten-3"
  >
    <div class="py-1"></div>

    <v-card
      max-width="80%"
      min-height="300px"
      class="mx-auto"
    >
      <v-list-item>
        <!--v-list-item-avatar color="grey"></v-list-item-avatar-->
        <v-list-item-content>
          <v-list-item-title class="headline">{{title}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-card-text>
        {{text}}
      </v-card-text>
    </v-card>

    <div class="py-1"></div>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
      legalName: null
    }
  },
  computed: {
    ...mapState('general', {
      info: state => state.info
    }),
    title () {
      if (!this.info) return ''
      if (!this.legalName) return ''

      if (this.legalName in this.info.legal) {
        return this.info.legal[this.legalName].name
      } else {
        return 'Esta Politica no xiste.'
      }
    },
    text () {
      if (!this.info) return ''
      if (!this.legalName) return ''

      if (this.legalName in this.info.legal) {
        return this.info.legal[this.legalName].text
      } else {
        return ''
      }
    }
  },
  created () {
    this.legalName = this.$route.query.q
  }
}
</script>
