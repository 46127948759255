<template>
  <v-dialog
    v-model="dialog"
    max-width="400"
  >
    <v-card>
      <v-card-title class="headline">Producto Agregado</v-card-title>

      <v-card-text>
        ¿Qué quieres hacer ahora?
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="green darken-1"
          text
          @click="close"
        >
          Seguir Comprando
        </v-btn>

        <v-btn
          color="green darken-1"
          text
          @click="goToCart"
        >
          Ir Al Carrito
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      dialog: false
    }
  },
  methods: {
    open () { this.dialog = true },
    close () { this.dialog = false },
    goToCart () {
      const url = this.$router.resolve({ name: 'cart' }).href

      window.location.assign(url)
    }
  }
}
</script>
