<template>
  <section>
    <v-card tile>
      <v-card-text v-if="cartIsLoading">
        <v-progress-circular indeterminate :size="70" :width="7" color="blue"></v-progress-circular>
        <br/>
        <h1 class="display-1">Cargando...</h1>
      </v-card-text>
      <v-card-text v-else-if="cart.items.length">
        <v-row dense
          v-for="(item, i) in cart.items"
          :key="i"
        >
          <v-col cols="12" sm="4" md="4" lg="4">
            <v-img
              v-if="item.product.photo"
              contain
              max-height="150px"
              :src="info ? $utilities.publicPath(item.product.photo): ''"
            ></v-img>
            <v-avatar v-else tile width="100%" height="150px">
              <!--grey lighten-5-->
              <v-icon size="100" left>mdi-archive</v-icon>
            </v-avatar>
          </v-col>
          <v-col cols="12" sm="8" md="8" lg="8">
            <h2 class="mb-3">{{item.product.name}}</h2>

            <v-row dense no-gutters>
              <v-col cols="12" sm="5" md="5" lg="5">
                <p class="title" v-if="readonly">{{item.quantity | quantityFormat}}</p>
                <v-text-field
                  v-else
                  style="width: 200px"
                  class="mx-auto"
                  append-outer-icon="mdi-plus"
                  @click:append-outer="increment(item)"
                  prepend-icon="mdi-minus"
                  @click:prepend="decrement(item)"
                  label="Cantidad"
                  v-model="item.quantity"
                  @change="onChangeQuantity(item)"
                  type="number"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3" lg="3">
                <p class="title">{{item.price | currencyFormat}}</p>
              </v-col>
              <v-col cols="12" sm="3" md="3" lg="3">
                <p class="title">{{item.price * item.quantity | currencyFormat}}</p>
              </v-col>
              <v-col cols="12" sm="1" md="1" lg="1">
                <v-btn text small @click="removeToCart(item)" v-if="!readonly">
                  <v-icon color="red">mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-divider inset></v-divider>
        </v-row>

        <div class="title font-weight-bold my-2">
          <v-row dense>
            <v-col cols="8" sm="8" md="8" lg="8" class="text-right">
              <p style="margin-top: 16px;">Gastos de envío:</p>
            </v-col>
            <v-col cols="4" sm="4" md="4" lg="4" class="text-left">
              <v-radio-group v-model="cart.totals.shipping" class="m-0">
                <v-radio
                  :label="cart.totals.shipping | currencyFormat"
                  :value="cart.totals.shipping"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-divider inset></v-divider>

          <v-row dense v-if="!cart.coupon && !readonly" class="text-left pt-2 pb-2 mt-2 mb-2">
            <v-col cols="12" sm="8" md="8" lg="8" class="text-right">
              <v-text-field
                label="Código"
                v-model="couponCode"
                outlined
                dense
                style="width: 200px"
                class="d-inline-block"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" lg="4">
              <v-btn color="success" @click="addCoupon">
                Agregar Cupón
              </v-btn>
            </v-col>
          </v-row>

          <v-row dense v-if="cart.coupon">
            <v-col cols="8" sm="8" md="8" lg="8" class="text-right pt-2 pb-2 mt-2 mb-2">
              <p>Cupón {{cart.coupon.code}} ({{formatValueType(cart.coupon)}}):</p>
            </v-col>
            <v-col cols="4" sm="4" md="4" lg="4" class="text-left">
              {{cart.totals.discount | currencyFormat}}
              <v-btn text small @click="removeCoupon(cart.coupon)" v-if="!readonly">
                <v-icon color="red">mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-divider inset></v-divider>

          <v-row dense class="pt-2 pb-2 mt-2 mb-2">
            <v-col cols="8" sm="8" md="8" lg="8" class="text-right">
              <h2>Total:</h2>
            </v-col>
            <v-col cols="4" sm="4" md="4" lg="4" class="text-left">
              <h2>{{cart.totals.total | currencyFormat}}</h2>
            </v-col>
          </v-row>

          <v-row class="text-right">
            <v-col>
              <slot name="footer"></slot>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-text v-else>
        <h1 class="display-1">El Carrito está Vacio</h1>
      </v-card-text>
    </v-card>
  </section>
</template>

<script>

import { mapState } from 'vuex'
import { format as formatValueType } from '@/utils/valueType'
import LoadingMixin from '@/plugins/Common/LoadingMixin.js'

export default {
  props: {
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      couponCode: null
    }
  },
  methods: {
    formatValueType,

    addCoupon () {
      if (this.readonly) { return null }
      if (this.statusIsLoading()) return

      this.statusLoading()

      if (this.couponCode) {
        this.$Models.Cart.addCoupon(this.couponCode)
          .then(res => {
            this.statusLoadingComplete()

            if (res.ok) {
              this.$store.commit('cart/setCart', res.cart)
              this.couponCode = null
              this.showSuccessMessage(res.message)
            } else {
              this.$store.commit('cart/setCart', res.cart)
              this.showError(res.message)
            }
          })
          .catch((err) => {
            this.statusLoadingFail()
            this.showError(err)
          })
      }
    },
    removeCoupon (coupon) {
      if (this.readonly) { return null }
      if (this.statusIsLoading()) return

      this.statusLoading()

      if (coupon) {
        this.$Models.Cart.removeCoupon(coupon.code)
          .then(res => {
            this.statusLoadingComplete()

            if (res.ok) {
              this.showSuccessMessage(res.message)
              this.$store.commit('cart/setCart', res.cart)
            } else {
              this.$store.commit('cart/setCart', res.cart)
              this.showError(res.message)
            }
          })
          .catch(err => {
            this.statusLoadingFail()
            this.showError(err)
          })
      }
    },
    increment (item) {
      if (this.readonly) { return null }

      item.quantity = parseInt(item.quantity) + 1
      this.onChangeQuantity(item)
    },
    decrement (item) {
      if (this.readonly) { return null }

      item.quantity = parseInt(item.quantity) - 1
      this.onChangeQuantity(item)
    },
    onChangeQuantity (item) {
      if (this.readonly) { return null }

      this.updateItemCart(item, { quantity: item.quantity })
    },
    updateItemCart (item, data) {
      if (this.readonly) { return null }
      if (this.statusIsLoading()) return

      this.statusLoading()

      this.$Models.Cart
        .updateItem({
          id: item.id,
          ...data
        })
        .then(res => {
          this.statusLoadingComplete()

          if (res.ok) {
            this.showSuccessMessage(res.message)
            this.$store.commit('cart/setCart', res.cart)
          } else {
            this.$store.commit('cart/setCart', res.cart)
            this.showError(res.message)
          }
        })
        .catch(err => {
          this.statusLoadingFail()
          this.showError(err)
        })
    },
    removeToCart (item) {
      if (this.readonly) { return null }
      if (this.statusIsLoading()) return

      this.statusLoading()

      this.$Models.Cart
        .remove(item.id)
        .then(res => {
          this.statusLoadingComplete()

          if (res.ok) {
            this.showSuccessMessage(res.message)
            this.$store.commit('cart/setCart', res.cart)
          } else {
            this.$store.commit('cart/setCart', res.cart)
            this.showError(res.message)
          }
        })
        .catch(err => {
          this.statusLoadingFail()
          this.showError(err)
        })
    }
  },
  computed: {
    ...mapState('cart', {
      cart: state => state.cart,
      cartIsLoading: state => state.cartIsLoading
    }),
    ...mapState('general', {
      info: state => state.info
    })
  },
  mixins: [LoadingMixin],
  created () {
  }
}
</script>
