<template>
  <section
    class="grey lighten-3"
  >
    <DialogAddToCartSuccess ref="dialogAddToCartSuccess"/>

    <div class="py-1"></div>

    <v-container class="text-center" v-if="product">
      <ProductCard :showSee="false" :product="product" @buy="addToCart" layoutType="list"/>
    </v-container>

    <div class="py-1"></div>
  </section>
</template>

<script>

import DialogAddToCartSuccess from '@/views/components/DialogAddToCartSuccess.vue'
import ProductCard from '@/views/components/ProductCard.vue'
import LoadingMixin from '@/plugins/Common/LoadingMixin.js'

export default {
  data () {
    return {
      product: null
    }
  },
  methods: {
    addToCart (product, quantity) {
      if (this.statusIsLoading()) return
      this.statusLoading()

      this.$Models.Cart.add(product, quantity)
        .then(res => {
          this.statusLoadingComplete()
          if (res.ok) {
            this.$store.commit('cart/setCart', res.cart)
            this.showSuccessMessage(res.message)
            this.$refs.dialogAddToCartSuccess.open()
          } else {
            this.$store.commit('cart/setCart', res.cart)
            this.showError(res.message)
          }
        })
        .catch((err) => {
          this.statusLoadingFail()
          this.showError(err)
        })
    },
    load () {
      if (this.statusIsLoading()) return
      this.statusLoading()

      this.$Models.Product
        .query()
        .where('online_store_slug', this.$route.params.productStoreSlug)
        .first()
        .then(product => {
          this.statusLoadingComplete()
          this.product = product
        })
        .catch(err => {
          this.statusLoadingFail()
          this.showError(err)
        })
    }
  },
  created () {
    this.load()
  },
  components: {
    ProductCard,
    DialogAddToCartSuccess
  },
  mixins: [LoadingMixin]
}
</script>
