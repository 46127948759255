<template>
  <v-main>
    <Banner/>
    <Links/>
    <Products/>
  </v-main>
</template>

<script>

import Banner from '@/views/components/Banner.vue'
import Links from '@/views/components/Links.vue'
import Products from '@/views/components/Products.vue'

export default {
  data () {
    return {
    }
  },
  methods: {
  },
  components: {
    Banner,
    Links,
    Products
  }
}
</script>
