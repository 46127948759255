<template>
  <section
    class="grey lighten-3 text-center"
  >
    <div class="py-1"></div>

    <DialogCustomerVerify ref="dialogCustomerVerify"/>

    <v-card
      max-width="80%"
      class="mx-auto"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="headline">Compra una Tarjeta Regalo</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-card-text>
        <v-chip-group
          column
          style="justify-content: center;"
          active-class="purple white--text"
        >
          <v-chip
            v-for="(button, i) in buttonsPrices"
            :key="i"
            filter
            @click="setGiftPrice(button.price)"
            class="text-center"
            style="min-width: 170px;"
          >
            {{button.price | currencyFormat}}
          </v-chip>
          <v-chip
            filter
            class="text-center"
            @click="setGiftPrice('custom')"
            style="min-width: 170px;"
          >
            Personalizado
          </v-chip>
        </v-chip-group>

        <InputMoney
          v-if="customPrice"
          label="Valor Personalizado"
          @input="setCustomGiftPrice"
          outlined
          dense
        ></InputMoney>
      </v-card-text>
    </v-card>

    <h2 class="display-1 font-weight-bold my-3">A quién lo quieres Regalar</h2>

    <v-responsive
      class="mx-auto mb-5"
      width="56"
    >
      <v-divider class="mb-1"></v-divider>

      <v-divider></v-divider>
    </v-responsive>

    <v-card
      max-width="80%"
      min-height="300px"
      class="mx-auto text-center"
    >
      <v-card-text>
        <v-form
          ref="recipientForm"
          lazy-validation
        >
          <v-text-field
            v-model="recipient.email"
            :rules="emailRules"
            label="E-mail"
            required
          ></v-text-field>

          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="recipient.first_name"
                :rules="nameRules"
                label="Nombres"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="recipient.last_name"
                :rules="nameRules"
                label="Apellidos (Opcional)"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-textarea
            v-model="recipient.message"
            :counter="190"
            :rows="3"
            label="Mensaje (Opcional)"
          ></v-textarea>
        </v-form>
      </v-card-text>
    </v-card>

    <h2 class="display-1 font-weight-bold my-3">Tus Datos</h2>

    <v-responsive
      class="mx-auto mb-5"
      width="56"
    >
      <v-divider class="mb-1"></v-divider>

      <v-divider></v-divider>
    </v-responsive>

    <v-card
      max-width="80%"
      min-height="300px"
      class="mx-auto text-center"
    >
      <v-card-text>

        <v-form
          ref="customerForm"
          lazy-validation
        >
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="customer.first_name"
                :rules="nameRules"
                label="Nombres"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="customer.last_name"
                :rules="nameRules"
                label="Apellidos"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-text-field
            v-model="customer.identification"
            @change="onChangeCustomerData"
            :rules="identificationNumberRules"
            label="Identificación"
            required
          ></v-text-field>

          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="customer.email"
                @change="onChangeCustomerData"
                :rules="emailRules"
                label="E-mail"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="customer.cellphone"
                @change="onChangeCustomerData"
                :rules="cellphoneRules"
                label="Teléfono / Celular"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-checkbox
            v-model="customer.accept_terms_and_conditions"
            :rules="termsAndConditionsRules"
            label="Acepta Términos y Condiciones?"
            required
            hide-details
          ></v-checkbox>

          <a target="blank" class="my-4 d-block text-left" :href="$router.resolve({ name: 'portal.legal', query: { q: 'gift_card_policies' } }).href">
            Políticas de tarjetas regalos*
          </a>

          <div class="text-right">
            <v-btn
              color="success"
              block large
              @click="preparePay"
            >
              Realizar Pago
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>

    <PayuPaymentForm></PayuPaymentForm>

    <div class="py-1"></div>
  </section>
</template>

<script>
import { nameRules, emailRules, termsAndConditionsRules, identificationNumberRules, cellphoneRules } from '@/utils/FormRules.js'
import { mapState } from 'vuex'
import PayuPaymentForm from '@/views/components/PayuPaymentForm'
import CustomerVerifyMixin from '@/plugins/Customer/VerifyMixin.js'
import MixinPay from '@/mixins/pay'
import LoadingMixin from '@/plugins/Common/LoadingMixin.js'
import RecaptchaMixin from '@/plugins/Common/RecaptchaMixin.js'

export default {
  name: 'GiftCards',
  data () {
    return {
      recaptchaToken: null,
      stepper: 1,
      buttonsPrices: [
        { price: 25000 },
        { price: 50000 },
        { price: 75000 },
        { price: 100000 }
      ],
      customPrice: false,

      giftCard: {},
      voucherGifted: null,
      defaultGiftCard: {
        price: null
      },
      customer: {},
      defaultCustomer: {
        first_name: null,
        last_name: null,
        identification: null,
        // identification_type: null, // Desarrollo2 Revisar Bombón
        email: null,
        cellphone: null,
        accept_terms_and_conditions: false
      },
      recipient: {},
      defaultRecipient: {
        email: null,
        first_name: null,
        last_name: null,
        message: null
      },
      nameRules,
      emailRules,
      termsAndConditionsRules,
      identificationNumberRules,
      cellphoneRules
    }
  },
  methods: {
    onGetRecaptchaToken (token) {
      this.recaptchaToken = token
    },
    setDefaultValues () {
      this.giftCard = Object.assign({}, this.defaultGiftCard)
      this.customer = Object.assign({}, this.defaultCustomer)
      this.recipient = Object.assign({}, this.defaultRecipient)
    },
    onChangeCustomerData () {
      this.verifyCustomerData(this.customer)
    },
    setCustomGiftPrice (price) {
      this.customPrice = true
      this.giftCard.price = parseInt(price)
    },
    setGiftPrice (price) {
      if (price === 'custom') {
        this.customPrice = true
        this.giftCard.price = 0
      } else {
        this.customPrice = false
        this.giftCard.price = parseInt(price)
      }
    },
    preparePay () {
      if (this.statusIsLoading()) return

      const customerFormIsValid = this.$refs.customerForm.validate()
      const recipientFormFormIsValid = this.$refs.recipientForm.validate()

      if (!customerFormIsValid) return
      if (!recipientFormFormIsValid) return

      this.statusLoading()

      this.getRecaptchaToken('giftCardPay')
        .then(token => { this.pay() })
    },
    pay () {
      const body = {
        giftCard: this.giftCard,
        customer: this.customer,
        recipient: this.recipient,
        recaptcha_token: this.recaptchaToken
      }

      // this.statusLoading()

      this.$Models.GiftCard
        .pay(body)
        .then(res => {
          this.statusLoadingComplete()
          this.showSuccessMessage()

          const data = res.data.result
          this.checkout = data.checkout
          this.voucherGifted = data.voucherGifted

          this.initPaymentCheckout()

          this.setDefaultValues()
        })
        .catch((err) => {
          this.showError(err)
          this.statusLoadingFail()
        })
    },
    totals () {
      return {
        amount: this.voucherGifted.price_gift,
        tax: 0,
        taxReturnBase: 0
      }
    }
  },
  computed: {
    ...mapState('general', {
      info: state => state.info
    })
  },
  watch: {
    info (nVal, oVal) {
      this.wathInfoSiteKey(nVal, oVal)
    }
  },
  created () {
    this.setDefaultValues()
  },
  components: {
    PayuPaymentForm
  },
  mixins: [MixinPay, LoadingMixin, RecaptchaMixin, CustomerVerifyMixin]
}
</script>
