<template>
  <v-main>
    <CartPay/>
  </v-main>
</template>

<script>

import CartPay from '@/views/components/CartPay.vue'

export default {
  data () {
    return {
    }
  },
  methods: {
  },
  components: {
    CartPay
  }
}
</script>
