const requiredRule = [
  v => !!v || 'Este Campo es Requerido'
]

const nameRules = [
  v => !!v || 'El Nombre es Requerido'
]

const emailRules = [
  v => !!v || 'Correo Electronico es Requerido',
  v => /.+@.+\..+/.test(v) || 'Correo Electronico es Invalido'
]

const identificationNumberRules = [
  v => !!v || 'La Identificación es Requerida',
  v => /[0-9]/.test(v) || 'La Identificación es Invalida'
]

const cellphoneRules = [
  v => !!v || 'Celular/Telefono es Requerido',
  v => /[0-9]/.test(v) || 'Celular/Telefono es Invalido'
]

const termsAndConditionsRules = [
  v => !!v || 'Debe Aceptar Términos y Condiciones para Continuar'
]

export {
  cellphoneRules,
  emailRules,
  identificationNumberRules,
  nameRules,
  requiredRule,
  termsAndConditionsRules
}

export default {
  cellphoneRules,
  emailRules,
  identificationNumberRules,
  nameRules,
  requiredRule,
  termsAndConditionsRules
}
