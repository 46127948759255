<template>
  <v-card
    max-width="100%"
    class="mx-auto"
    outlined
  >
    <template v-if="layoutType === 'list'">
      <v-card-text>
        <v-row class="text-left">
          <v-col cols="12" sm="6" md="6">
            <v-img
              contain
              v-if="product.photo"
              :src="$utilities.publicPath(product.photo)"
              height="300px"
            ></v-img>
            <v-avatar v-else tile width="100%" height="300px">
              <!--grey lighten-5-->
              <v-icon size="300" left>mdi-archive</v-icon>
            </v-avatar>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <h2 class="mb-3">{{product.name}}</h2>

            <br>
            <template v-if="productToShow">
            <span v-if="!productToShow.stock.out_stock" class="subtitle-1 green--text">
              <span v-if="info ? info.show_quantity_in_stock : false">
                {{productToShow.stock.quantity_available | quantityFormat}} en Stock
              </span>
              <span v-else>
                Disponible
              </span>
            </span>
            <span v-else class="subtitle-1 red--text">
              No hay Disponible
            </span>

            <v-spacer></v-spacer>

            <!--hr-->
            <v-row no-gutters v-if="productToShow.price" class="title font-weight-bold my-4">
              <v-col cols="12" sm="6" md="6" lg="6" class="mt-3">
                <span class="black--text display-1 font-weight-bold">
                  {{productToShow.price.value_price | currencyFormat}}
                </span>
                <span v-if="productToShow.price.special_price" class="grey--text title" style="text-decoration: line-through;">
                  {{productToShow.price.retail_price | currencyFormat}}
                </span>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6" class="mt-3" v-if="showBuy">
                <v-text-field
                  style="width: 150px"
                  class="mx-auto"
                  append-outer-icon="mdi-plus"
                  @click:append-outer="incrementDefaultQuantity"
                  prepend-icon="mdi-minus"
                  @click:prepend="decrementDefaultQuantity"
                  label="Cantidad"
                  v-model="defaultQuantity"
                  type="number"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            </template>

            <!--hr-->

            <v-btn
              text
              color="deep-black accent-4"
              @click="onClickSee"
              v-if="showSee"
            >
              Ver
            </v-btn>
            <v-btn
              text
              outlined
              color="deep-black accent-4 black"
              @click="onClickBuy"
              v-if="showBuy"
            >
              Comprar
            </v-btn>
            <v-chip v-if="inCart" color="success" outlined class="my-1 mx-1 font-weight-bold">
              Ya esta en carrito
              <!--v-icon>mdi-cart</v-icon-->
            </v-chip>

            <v-btn class="ml-3" icon @click="copyLink">
              <v-icon class="success--text" @click="openWhatsapp">mdi-whatsapp</v-icon>
            </v-btn>

            <v-btn class="share-button ml-3" icon :data-clipboard-text="getUrlProduct()" @click="copyLink">
              <v-icon>mdi-share-variant</v-icon>
            </v-btn>

            <v-select
              v-if="product.can_variant"
              v-model="variantSelected"
              @change="onChangeVariantSelected"
              :items="product.variants"
              item-text="name"
              item-value="id"
              label="Seleccionar ..."
              return-object
              single-line
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="text-left">
          <v-col>
            <p class="title">Descripción</p>
            <p v-if="product.online_store_description">{{product.online_store_description}}</p>
            <p v-else>Sin Descripción</p>
          </v-col>
        </v-row>
      </v-card-text>
    </template>

    <template v-if="layoutType === 'card'">
      <v-img
        contain
        v-if="product.photo"
        :src="$utilities.publicPath(product.photo)"
        height="200"
      ></v-img>
      <v-avatar v-else tile width="100%" height="200">
        <!--grey lighten-5-->
        <v-icon size="300" left>mdi-archive</v-icon>
      </v-avatar>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            <span class="black--text" style="font-size: 18px;">{{product.name}}</span>
          </v-list-item-title>
          <v-list-item-subtitle>
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="productToShow">
            <v-row no-gutters v-if="productToShow.price" class="title font-weight-bold">
              <v-col>
                <span class="black--text display-1 font-weight-bold">
                  {{productToShow.price.value_price | currencyFormat}}
                </span>
                <span v-if="productToShow.price.special_price" class="grey--text subtitle-1" style="text-decoration: line-through;">
                  {{productToShow.price.retail_price | currencyFormat}}
                </span>
              </v-col>
            </v-row>

            <v-spacer></v-spacer>

            <span v-if="!productToShow.stock.out_stock" class="subtitle-1 green--text">
              <span v-if="info ? info.show_quantity_in_stock : false">
                {{productToShow.stock.quantity_available | quantityFormat}} en Stock
              </span>
              <span v-else>
                Disponible
              </span>
            </span>
            <span v-else class="subtitle-1 red--text">
              No hay Disponible
            </span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-card-actions>
        <v-btn
          text
          color="deep-black accent-4"
          @click="onClickSee"
          v-if="showSee"
        >
          Ver
        </v-btn>
        <v-btn
          outlined
          color="deep-black accent-4 black"
          @click="onClickBuy"
          v-if="showBuy"
        >
          Comprar
        </v-btn>
        <v-chip v-if="inCart" color="success" outlined class="my-1 mx-1 font-weight-bold">
          Ya esta en carrito
          <!--v-icon>mdi-cart</v-icon-->
        </v-chip>

        <v-spacer></v-spacer>
        <v-btn icon :data-clipboard-text="getUrlProduct()" class="share-button" @click="copyLink">
          <v-icon>mdi-share-variant</v-icon>
        </v-btn>
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
import ClipboardJS from 'clipboard'

import { mapState } from 'vuex'
import LoadingMixin from '@/plugins/Common/LoadingMixin.js'

export default {
  data () {
    return {
      defaultQuantity: 1,
      variantSelected: null,
      productToShow: null
    }
  },
  props: {
    layoutType: {
      type: String,
      default: 'card'
    },
    product: {
      type: Object,
      required: true
    },
    showSee: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState('cart', {
      cart: state => state.cart,
      cartIsLoading: state => state.cartIsLoading
    }),
    ...mapState('general', {
      info: state => state.info
    }),
    inCart () {
      let object
      if (this.variantSelected) {
        object = this.cart.items.find(item => item.product.id === this.variantSelected.id)
      } else {
        object = this.cart.items.find(item => item.product.id === this.product.id)
      }
      if (object) return true
      return false
    },
    showBuy () {
      if (this.variantSelected) {
        if (this.variantSelected.stock.out_stock) return false
        if (this.inCart) return false
        return true
      } else {
        if (this.product.stock.out_stock) return false
        if (this.inCart) return false
        return true
      }
    }
  },
  methods: {
    onChangeVariantSelected (value) {
      this.productToShow = value
    },
    openWhatsapp () {
      const cellphone = `${this.info.contact_cellphone_indicator}${this.info.contact_cellphone}`
      const url = `https://api.whatsapp.com/send?phone=${cellphone}`
      window.open(url)
    },
    incrementDefaultQuantity () {
      if (!this.showBuy) { return null }
      this.defaultQuantity = parseInt(this.defaultQuantity) + 1
    },
    decrementDefaultQuantity () {
      if (!this.showBuy) { return null }
      this.defaultQuantity = parseInt(this.defaultQuantity) - 1
    },
    copyLink () {
      const clipboard = new ClipboardJS('.share-button')

      clipboard.on('success', (e) => {
        this.showInfoMessage('Copiado')
        clipboard.destroy()
      })

      clipboard.on('error', (e) => {
        this.showInfoMessage('No pudimos copiar el link.')
        clipboard.destroy()
      })
    },
    getUrlProduct () {
      const urlResolved = this.$router.resolve({
        name: 'portal.products.detail',
        params: { productStoreSlug: this.product.online_store_slug }
      }).href

      const url = `${window.location.protocol}//${window.location.host}${urlResolved}`

      return url
    },
    onClickSee () { this.$emit('see', this.product) },
    onClickBuy () {
      let dQuantity = null
      if (!this.inCart) {
        dQuantity = parseInt(this.defaultQuantity)
        // verificar que el productos no sea un variant master
      }

      if (this.variantSelected) {
        this.$emit('buy', this.variantSelected, dQuantity)
      } else {
        this.$emit('buy', this.product, dQuantity)
      }
    }
  },
  watch: {
    product (nVal, oVal) {
      if (nVal) {
        if (nVal.can_variant) {
          this.productToShow = nVal.variants[0]
          this.variantSelected = nVal.variants[0]
        } else {
          this.product = nVal
        }
      }
    }
  },
  created () {
    if (this.product.can_variant) {
      this.productToShow = this.product.variants[0]
      this.variantSelected = this.product.variants[0]
    } else {
      this.productToShow = this.product
      this.variantSelected = this.product
    }
  },
  mixins: [LoadingMixin]
}
</script>
