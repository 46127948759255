<template>
  <v-dialog
    v-model="dialog"
  >
    <v-card>
      <v-card-title class="headline">Verificación de Datos</v-card-title>

      <v-card-text>
        Hemos encontrado lo siguiente:

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title v-for="(message, index) in list" :key="index">
              <p class="title">{{message}}</p>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="green darken-1"
          text
          @click="close"
        >
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      dialog: false,
      list: []
    }
  },
  methods: {
    open (list = []) {
      this.list = list
      this.dialog = true
    },
    close () {
      this.list = []
      this.dialog = false
    }
  }
}
</script>
