import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import i18n from './common/plugins/vue-i18n'
import vuetify from './common/plugins/vuetify'
// import '@babel/polyfill'
import '@mdi/font/css/materialdesignicons.css'

import Models from './api/main.js'
import Utilities from './plugins/Utilities.js'

import currencyFormat from './filters/currency'
import { quantityFormat, numberFormat } from './filters/number'
import InputMoney from './components/common/InputMoney.vue'

Vue.filter('currencyFormat', currencyFormat)
Vue.filter('quantityFormat', quantityFormat)
Vue.filter('numberFormat', numberFormat)

Vue.component('InputMoney', InputMoney)

Vue.config.productionTip = false
Vue.prototype.$Models = Models

Vue.use(Utilities)

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
