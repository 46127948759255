<template>
  <section id="banner">
    <v-carousel
      cycle
      height="500"
      reverse-transition="fade-transition"
      transition="fade-transition"
      hide-delimiter-background
      :show-arrows="true"
      hide-delimiters
      show-arrows-on-hover
    >
      <v-carousel-item
        v-for="(slide, i) in slides"
        :key="i"
        :src="$utilities.publicPath(slide.url)"
      >
        <v-theme-provider dark >
        </v-theme-provider>
      </v-carousel-item>
    </v-carousel>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
      slides: []
    }
  },
  computed: {
    ...mapState('general', {
      info: state => state.info
    })
  },
  watch: {
    info (nValue) {
      if (nValue) {
        if (nValue.banners) {
          this.slides = nValue.banners
        }
      }
    }
  }
}
</script>
