<template>
  <v-main>
    <ProductDetail/>
  </v-main>
</template>

<script>

import ProductDetail from '@/views/components/ProductDetail.vue'

export default {
  data () {
    return {
    }
  },
  methods: {
  },
  components: {
    ProductDetail
  }
}
</script>
