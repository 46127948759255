<template>
  <v-main>
    <Products/>
  </v-main>
</template>

<script>

import Products from '@/views/components/Products.vue'

export default {
  data () {
    return {
    }
  },
  methods: {
  },
  components: {
    Products
  }
}
</script>
