<template>
  <section>
    <DialogAddToCartSuccess ref="dialogAddToCartSuccess"/>

    <div class="py-1"></div>

    <v-container class="text-center">
      <!--
      <h2 class="display-2 font-weight-bold mb-3">Productos</h2>

      <v-responsive
        class="mx-auto mb-5"
        width="56"
      >
        <v-divider class="mb-1"></v-divider>

        <v-divider></v-divider>
      </v-responsive>
      -->

      <v-card flat tile>
          <v-row>
            <v-col>
              <v-autocomplete
                :items="productCategories"
                label="Categorías"
                hide-no-data
                hide-selected
                item-text="name"
                item-value="id"
                clearable
                outlined
                @input="(value) => setFilter('category_id', value)"
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete
                :items="productBrands"
                label="Marcas"
                hide-no-data
                hide-selected
                item-text="name"
                item-value="id"
                clearable
                outlined
                @input="(value) => setFilter('brand_id', value)"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row v-if="filters.q">
            <h3>Busqueda por: {{filters.q}}</h3>
          </v-row>

          <v-row v-if="products.length === 0">
            <v-col>
              <h2 class="mb-3">No hemos encontrado resultados</h2>
              <h2 class="mb-3">Puede intentar de otra forma</h2>
              <v-text-field
                label="Busca Productos"
                append-icon="mdi-magnify"
                outlined
                v-model="filters.q"
                class="mx-auto"
                style="width: 300px;"
                @keydown.enter="() => setFilter('q', filters.q)"
                @click:append="() => setFilter('q', filters.q)"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
      </v-card>

      <v-row>
        <v-col
          v-for="(product, i) in products"
          :key="i"
          :cols="layoutType === 'card' ? 12 : 12"
          :sm="layoutType === 'card' ? 6 : 12"
          :md="layoutType === 'card' ? 4 : 12"
          :lg="layoutType === 'card' ? 3 : 12"
        >
          <ProductCard :product="product" @see="goToDetail" @buy="addToCart" :layoutType="layoutType"/>
        </v-col>
      </v-row>

      <v-btn
        :loading="status.loading"
        @click="toNextPage"
        small
        color="green"
        v-if="showLoadMore"
      >
        Cargar Mas
      </v-btn>
    </v-container>

    <div class="py-1"></div>
  </section>
</template>

<script>
import DialogAddToCartSuccess from '@/views/components/DialogAddToCartSuccess.vue'
import ProductCard from '@/views/components/ProductCard.vue'
import LoadingMixin from '@/plugins/Common/LoadingMixin.js'

export default {
  data () {
    return {
      layoutType: 'card',
      products: [],
      productCategories: [],
      productBrands: [],
      filters: {
        q: null,
        category_id: null,
        brand_id: null
      },
      pagination: {
        currentPage: 1,
        totalRows: 1,
        totalPages: 1,
        perPage: 20
      }
    }
  },
  methods: {
    toNextPage () {
      const nextPage = this.pagination.currentPage + 1
      if (nextPage <= this.pagination.totalPages) {
        this.pagination.currentPage = nextPage
        this.load()
      }
    },
    resetLoad () {
      this.products = []
      this.pagination.currentPage = 1
    },
    setFilter (filterName, value) {
      this.resetLoad()

      this.filters[filterName] = value

      this.load()
    },
    load () {
      if (this.statusIsLoading()) return

      const query = this.$Models.Product.query()

      const qValue = this.filters.q
      if (qValue !== null && qValue !== undefined) {
        query.where('q', qValue)
      }

      const categoryValue = this.filters.category_id
      if (categoryValue !== null && categoryValue !== undefined) {
        query.where('category_id', categoryValue)
      }

      const brandValue = this.filters.brand_id
      if (brandValue !== null && brandValue !== undefined) {
        query.where('brand_id', brandValue)
      }

      query.forPage(this.pagination.currentPage)

      this.statusLoading()
      query.get()
        .then(res => {
          if (this.pagination.currentPage === 1) {
            this.pagination.totalRows = res.count
            this.pagination.totalPages = Math.ceil(this.pagination.totalRows / this.pagination.perPage)
          }
          return res
        })
        .then(res => {
          this.products = this.products.concat(res.items)
          this.statusLoadingComplete()
        })
        .catch((err) => {
          this.statusLoadingFail()
          this.showError(err)
        })
    },
    goToDetail (product) {
      const url = this.$router.resolve({
        name: 'portal.products.detail',
        params: { portalId: this.$route.params.portalId, productStoreSlug: product.online_store_slug }
      }).href

      window.location.assign(url)
    },
    addToCart (product, quantity) {
      if (this.statusIsLoading()) return

      this.statusLoading()

      this.$Models.Cart.add(product, quantity)
        .then(res => {
          this.statusLoadingComplete()
          if (res.ok) {
            this.$store.commit('cart/setCart', res.cart)
            this.showSuccessMessage(res.message)
            this.$refs.dialogAddToCartSuccess.open()
          } else {
            this.$store.commit('cart/setCart', res.cart)
            this.showError(res.message)
          }
        })
        .catch((err) => {
          this.statusLoadingFail()
          this.showError(err)
        })
    }
  },
  created () {
    const qValue = this.$route.query.q
    if (qValue !== null && qValue !== undefined) {
      this.filters.q = qValue
    }

    const categoryValue = this.$route.query.category
    if (categoryValue !== null && categoryValue !== undefined) {
      this.filters.category_id = categoryValue
    }
    const brandValue = this.$route.query.brand
    if (brandValue !== null && brandValue !== undefined) {
      this.filters.brand_id = brandValue
    }

    this.load()

    this.$Models.Product.getFilters()
      .then(res => {
        this.productCategories = res.categories.items
        this.productBrands = res.brands.items
      })
      .catch(err => {
        this.showError(err)
      })
  },
  computed: {
    showLoadMore () {
      return this.pagination.currentPage < this.pagination.totalPages
    }
  },
  components: {
    ProductCard,
    DialogAddToCartSuccess
  },
  mixins: [LoadingMixin]
}
</script>
