<template>
  <v-main>
    <GiftCards/>
  </v-main>
</template>

<script>

import GiftCards from '@/views/components/GiftCards.vue'

export default {
  data () {
    return {
    }
  },
  methods: {
  },
  components: {
    GiftCards
  }
}
</script>
