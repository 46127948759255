<template>
  <v-main>
    <Contact/>
  </v-main>
</template>

<script>

import Contact from '@/views/components/Contact.vue'

export default {
  data () {
    return {
    }
  },
  methods: {
  },
  components: {
    Contact
  }
}
</script>
