import DialogCustomerVerify from '@/views/components/DialogCustomerVerify.vue'

const Mixin = {
  methods: {
    verifyCustomerData (customer) {
      const data = {}

      if (customer.identification) { data.identification = customer.identification }
      if (customer.email) { data.email = customer.email }
      if (customer.cellphone) { data.cellphone = customer.cellphone }

      if (Object.keys(data).length) {
        this.$Models.Customer.verify(data)
          .then(res => {
            const messages = []

            if (res.email.exists) {
              messages.push(`El Correo Electrónico ${customer.email} ya esta en uso.`)
            }
            if (res.cellphone.exists) {
              messages.push(`El Celular ${customer.cellphone} ya esta en uso.`)
            }

            // if (messages.length) { this.$refs.dialogCustomerVerify.open(messages) }
          })
          .catch(err => {
            this.showError(err)
          })
      }
    }
  },
  components: {
    DialogCustomerVerify
  }
}

export default Mixin
