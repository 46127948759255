import Vue from 'vue'
import Router from 'vue-router'

import Base from '@/views/templates/Base.vue'

import GenericContainer from '@/views/templates/Container.vue'

import PortalNotFoundPage from '@/views/pages/PortalNotFoundPage.vue'
import PortalInactivePage from '@/views/pages/PortalInactivePage.vue'
import MainPage from '@/views/pages/MainPage.vue'
import ProductDetailPage from '@/views/pages/Product/ProductDetailPage.vue'
import ProductsPage from '@/views/pages/Product/ProductsPage.vue'
import ContactPage from '@/views/pages/Product/ContactPage.vue'
import LegalPage from '@/views/pages/LegalPage.vue'
import OrderCompletePage from '@/views/pages/OrderCompletePage.vue'
import GiftCardsPage from '@/views/pages/GiftCardsPage.vue'
import CartPage from '@/views/pages/Cart/CartPage.vue'
import CartPayPage from '@/views/pages/Cart/CartPayPage.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/:portalId/not-found',
      component: PortalNotFoundPage,
      name: 'portal-not-found',
      meta: { label: '' }
    },
    {
      path: '/:portalId/inactive',
      component: PortalInactivePage,
      name: 'portal-inactive',
      meta: { label: '' }
    },
    {
      path: '/:portalId',
      component: Base,
      meta: { label: '' },
      children: [
        {
          path: 'orders/complete',
          component: OrderCompletePage,
          name: 'portal.orders.complete',
          meta: { label: '' }
        },
        {
          path: 'legal',
          component: LegalPage,
          name: 'portal.legal',
          meta: { label: '' }
        },
        {
          path: 'gift-cards',
          component: GiftCardsPage,
          name: 'portal.giftCards',
          meta: { label: '' }
        },
        {
          path: '',
          name: 'portal.index',
          component: MainPage,
          meta: { label: '' }
        },
        {
          path: 'contact',
          name: 'portal.contact',
          component: ContactPage,
          meta: { label: '' }
        },
        {
          path: 'products/search',
          name: 'portal.products.search',
          component: ProductsPage,
          meta: { label: '' }
        },
        {
          path: 'products/:productStoreSlug',
          name: 'portal.products.detail',
          component: ProductDetailPage,
          meta: { label: '' }
        },
        {
          path: 'cart',
          component: GenericContainer,
          meta: {
            label: '',
            middlewares: []
          },
          children: [
            {
              path: '',
              component: CartPage,
              name: 'cart',
              meta: { label: '' }
            },
            {
              path: 'summary',
              component: CartPayPage,
              name: 'cart.summary',
              meta: { label: '' }
            }
          ]
        }
      ]
    }
  ]
})
