import { RawConnection } from '@/api/ORM/connection/index'

export default {
  namespaced: true,
  state: {
    pageIsLoading: false,
    info: null,
    snackbar: {
      state: false,
      color: '',
      message: ''
    }
  },
  mutations: {
    setDepartaments (state, departaments) {
      state.departaments = departaments
    },
    setMunicipalities (state, municipalities) {
      state.municipalities = municipalities
    },
    setLoading (state, value) {
      state.pageIsLoading = value
    },
    setSnackbar (state, config) {
      state.snackbar = {
        color: config.color,
        message: config.message,
        state: true
      }
    },
    setInfo (state, info) {
      state.info = info
    }
  },
  actions: {
    loadInfo (context, portalId) {
      const connection = new RawConnection()

      const url = '/api/v1/company/info'

      return connection.post(url, { portalId })
        .then(result => result.data.data.result)
        .then(res => {
          context.commit('setInfo', res)
          return res
        })
    },
    loadDepartaments (context, portalId) {
      const connection = new RawConnection()

      const url = '/api/v1/company/departaments'

      return connection.post(url, { portalId })
        .then(result => result.data.data.result)
        .then(res => {
          context.commit('setDepartaments', res)
          return res
        })
    },
    loadMunicipalities (context, portalId) {
      const connection = new RawConnection()

      const url = '/api/v1/company/municipalities'

      return connection.post(url, { portalId })
        .then(result => result.data.data.result)
        .then(res => {
          context.commit('setMunicipalities', res)
          return res
        })
    },
    setLoading (context, value) {
      context.commit('setLoading', value)
    },
    setSnackbar (context, config) {
      context.commit('setSnackbar', config)
    }
  },
  getters: {
    car (state) {
      return state.cart
    }
  }
}
