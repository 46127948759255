<template>
  <section
    class="grey lighten-3"
  >
    <v-col cols="12" sm="12" md="8" lg="6" class="mx-auto">
    <div class="py-1"></div>

    <DialogCustomerVerify ref="dialogCustomerVerify"/>

    <v-container class="text-center">
      <h2 class="display-2 font-weight-bold mb-3">Resumen</h2>

      <v-responsive
        class="mx-auto mb-5"
        width="56"
      >
        <v-divider class="mb-1"></v-divider>

        <v-divider></v-divider>
      </v-responsive>

      <CartSummary :readonly="true" ref="cartSummary"/>

      <h2 class="display-1 font-weight-bold my-3">Datos para la Compra</h2>

      <v-responsive
        class="mx-auto mb-5"
        width="56"
      >
        <v-divider class="mb-1"></v-divider>

        <v-divider></v-divider>
      </v-responsive>

      <v-card tile>
      <v-card-text>
        <v-form
          ref="customerForm"
          lazy-validation
        >
          <v-text-field
            v-model="customer.identification"
            outlined dense
            :rules="identificationNumberRules"
            @change="onChangeCustomerData"
            label="Identificación"
            required
          ></v-text-field>

          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="customer.first_name"
                outlined dense
                :rules="nameRules"
                label="Nombres"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="customer.last_name"
                outlined dense
                :rules="nameRules"
                label="Apellidos"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="customer.email"
                outlined dense
                @change="onChangeCustomerData"
                :rules="emailRules"
                label="E-mail"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="customer.cellphone"
                outlined dense
                @change="onChangeCustomerData"
                :rules="cellphoneRules"
                label="Teléfono / Celular"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6" md="6">
              <SelectDepartament
                label="Departamento"
                v-model="shipping.departament_code"
                outlined dense
                :rules="requiredRule"
                @input="onChangeDepartamentCode"
              />
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <SelectMunicipality
                v-show="departamentCodeSelected"
                label="Municipio"
                ref="selectMunicipality"
                v-model="shipping.municipality_code"
                outlined dense
                :departamentCode="shipping.departament_code"
                :rules="requiredRule"
              />
              <p v-if="!departamentCodeSelected" class="subtitle-1">Seleccione un Departamento</p>
            </v-col>
          </v-row>

          <v-text-field
            v-model="shipping.location_address"
            outlined dense
            :rules="requiredRule"
            label="Dirección"
            required
          ></v-text-field>

          <v-text-field
            v-model="shipping.location_city"
            outlined dense
            :rules="requiredRule"
            label="Barrio"
            required
          ></v-text-field>

          <v-textarea
            v-model="shipping.notes"
            outlined dense
            :counter="500"
            :rows="3"
            label="Comentarios sobre en envío (Opcional)"
          ></v-textarea>

          <h2 class="display-1 font-weight-bold my-3">Dirección de Facturación</h2>

          <v-responsive
            class="mx-auto mb-5"
            width="56"
          >
            <v-divider class="mb-1"></v-divider>

            <v-divider></v-divider>
          </v-responsive>

          <v-row>
            <v-col cols="12" sm="6" md="6">
              <SelectDepartament
                label="Departamento"
                v-model="billing.departament_code"
                outlined dense
                @input="onChangeBillingDepartamentCode"
              />
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <SelectMunicipality
                v-show="billingDepartamentCodeSelected"
                label="Municipio"
                ref="selectBillingMunicipality"
                v-model="billing.municipality_code"
                outlined dense
                :departamentCode="billing.departament_code"
              />
              <p v-if="!billingDepartamentCodeSelected" class="subtitle-1">Seleccione un Departamento</p>
            </v-col>
          </v-row>

          <v-text-field
            v-model="billing.location_address"
            outlined dense
            label="Dirección"
            required
          ></v-text-field>

          <v-text-field
            v-model="billing.location_postal_code"
            outlined dense
            label="Código Postal"
            required
          ></v-text-field>

          <v-text-field
            v-model="billing.location_neighborhood"
            outlined dense
            label="Barrio"
            required
          ></v-text-field>

          <v-responsive
            class="mx-auto mb-5"
            width="56"
          >
            <v-divider class="mb-1"></v-divider>

            <v-divider></v-divider>
          </v-responsive>

          <!-- check later only for organicnails -->
          <v-row dense>
            <v-col cols="6" sm="6" md="6" lg="6">
              <v-radio-group v-model="paymentMethod" required class="m-0" label="Seleccionar forma de pago:">
                <v-radio
                  label="MERCADO PAGO"
                  value="PAYMENT_GATEWAY"
                  style="margin: 15px 0;"
                ></v-radio>
                <v-radio
                  label="PAGO TRANSFERENCIA O CONTRA ENTREGA"
                  value="EXTERNAL_TRANSFER"
                  style="margin: 15px 0;"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="6" sm="6" md="6" lg="6" class="text-left">
              <div style="text-align: left; font-size: 14px; color: gray; margin-top: 16px;">
                <div v-if="paymentMethod === 'PAYMENT_GATEWAY'">
                  <span>Le permite pagar con tarjeta:</span>
                  <br>
                  <br>
                  <span>VISA, AMERCIAN EXPRES, MASTERCARD, DINNERS, TARJETA CODENSA, PSE</span>
                  <br>
                  <span>También puede pagar con:</span>
                  <br>
                  <span>EFECTY, 4-72 y SER</span>
                  <br>
                </div>

                <div v-if="paymentMethod === 'EXTERNAL_TRANSFER'">
                  <span>PAGO MANUAL POR TRANSFERENCIA U OTROS MEDIOS DE PAGO COMO NEQUI, DAVIPLATA Y MÁS</span>
                  <br>
                  <i>(Te enviaremos un correo con todas las intrucciones para realizar tu pago manual)</i>
                  <br>
                </div>
              </div>
            </v-col>
          </v-row>
          <!-- check later only for organicnails -->

          <v-responsive
            class="mx-auto mb-5"
            width="56"
          >
            <v-divider class="mb-1"></v-divider>

            <v-divider></v-divider>
          </v-responsive>

          <v-checkbox
            v-model="customer.accept_terms_and_conditions"
            :rules="termsAndConditionsRules"
            label="Acepta Términos y condiciones?"
            required
            hide-details
          ></v-checkbox>

          <a target="blank" class="text-left my-4 d-block" :href="$router.resolve({ name: 'portal.legal', query: { q: 'terms_and_conditions' } }).href">
            Términos y Condiciones*
          </a>

          <div class="text-right">
            <v-btn
              color="success"
              block large
              @click="preparePay"
            >
              Realizar Pago
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
      </v-card>
    </v-container>

    <PayuPaymentForm></PayuPaymentForm>

    <div class="py-1"></div>
    </v-col>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { nameRules, emailRules, identificationNumberRules, cellphoneRules, termsAndConditionsRules, requiredRule } from '@/utils/FormRules.js'
import CartSummary from '@/views/components/CartSummary'
import PayuPaymentForm from '@/views/components/PayuPaymentForm'

import SelectDepartament from '@/components/common/SelectDepartament.vue'
import SelectMunicipality from '@/components/common/SelectMunicipality.vue'

import MixinPay from '@/mixins/pay'
import CustomerVerifyMixin from '@/plugins/Customer/VerifyMixin.js'
import LoadingMixin from '@/plugins/Common/LoadingMixin.js'
import RecaptchaMixin from '@/plugins/Common/RecaptchaMixin.js'

export default {
  data () {
    return {
      recaptchaToken: null,
      order: null,
      shipping: {},
      billing: {},
      defaultShipping: {
        notes: null,
        departament_code: null,
        municipality_code: null,
        location_address: null,
        location_city: null
      },
      defaultBilling: {
        location_address: null,
        location_neighborhood: null,
        location_postal_code: null,
        departament_code: null,
        municipality_code: null
      },
      customer: {},
      defaultCustomer: {
        first_name: null,
        last_name: null,
        identification: null,
        // identification_type: null, // Desarrollo2 Revisar Bombón
        email: null,
        cellphone: null,
        accept_terms_and_conditions: false
      },
      paymentMethod: null,
      nameRules,
      emailRules,
      identificationNumberRules,
      cellphoneRules,
      termsAndConditionsRules,
      requiredRule
    }
  },
  methods: {
    onGetRecaptchaToken (token) {
      this.recaptchaToken = token
    },
    setDefaultValues () {
      this.shipping = Object.assign({}, this.defaultShipping)
      this.billing = Object.assign({}, this.defaultBilling)
      this.customer = Object.assign({}, this.defaultCustomer)
    },
    onChangeDepartamentCode (departamentCode) {
      this.shipping.municipality_code = null
      this.$refs.selectMunicipality.setItemsByDepartament(departamentCode)
    },
    onChangeBillingDepartamentCode (departamentCode) {
      this.billing.municipality_code = null
      this.$refs.selectBillingMunicipality.setItemsByDepartament(departamentCode)
    },
    onChangeCustomerData () {
      this.verifyCustomerData(this.customer)
    },
    preparePay () {
      if (this.statusIsLoading()) return

      const customerFormIsValid = this.$refs.customerForm.validate()
      if (!customerFormIsValid) return

      this.statusLoading()

      this.getRecaptchaToken('cartPay')
        .then(token => { this.pay() })
    },
    pay () {
      const body = {
        // cart: this.cart,
        payment_method: this.paymentMethod,
        customer: this.customer,
        shipping: this.shipping,
        billing: this.billing,
        recaptcha_token: this.recaptchaToken
      }

      // this.statusLoading()

      this.$Models.Cart
        .pay(body)
        .then(res => {
          this.statusLoadingComplete()
          this.showSuccessMessage()

          const data = res.data.result
          this.checkout = data.checkout
          this.order = data.order

          this.initPaymentCheckout()

          this.setDefaultValues()
        })
        .catch((err) => {
          this.showError(err)
          this.statusLoadingFail()
        })
    },
    totals () {
      return {
        amount: this.order.total,
        tax: this.order.total_tax,
        taxReturnBase: this.order.total - this.order.total_tax
      }
    }
  },
  computed: {
    departamentCodeSelected () {
      return this.shipping.departament_code !== null && this.shipping.departament_code !== undefined
    },
    billingDepartamentCodeSelected () {
      return this.billing.departament_code !== null && this.billing.departament_code !== undefined
    },
    ...mapState('general', {
      info: state => state.info
    }),
    ...mapState('cart', {
      cart: state => state.cart,
      cartIsLoading: state => state.cartIsLoading
    })
  },
  watch: {
    info (nVal, oVal) {
      this.wathInfoSiteKey(nVal, oVal)
    }
  },
  created () {
    this.setDefaultValues()
  },
  components: {
    CartSummary,
    PayuPaymentForm,
    SelectDepartament,
    SelectMunicipality
  },
  mixins: [MixinPay, LoadingMixin, RecaptchaMixin, CustomerVerifyMixin]
}
</script>
