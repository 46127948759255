import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#1A73E8',
        secondary: '#e8ecfa',
        accent: '#5d78ff',
        error: '#EA4334',
        info: '#2EA1F1',
        success: '#37A853',
        warning: '#FBBC07'
      }
    }
  }
})
