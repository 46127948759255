<template>
  <v-app>
    <v-layout row justify-center>
      <v-dialog v-model="loadingPage" persistent content overlay-color="white" overlay-opacity="0.9">
        <v-container fill-height tile>
          <v-layout column justify-center align-center>
            <v-progress-circular indeterminate :size="70" :width="7" color="blue"></v-progress-circular>

            <br/>

            <h2>Pronto estara lizto...</h2>
          </v-layout>
        </v-container>
      </v-dialog>
    </v-layout>

    <v-snackbar
      v-model="snackbar.state"
      top
      :color="snackbar.color"
      :timeout="4000"
    >
      <h3 class="d-flex justify-space-between align-center">
        <span>{{snackbar.message}}</span>
        <v-btn text small @click="snackbar.state = false"> <v-icon color="white">mdi-close</v-icon> </v-btn>
      </h3>
    </v-snackbar>

    <v-app-bar
      app
      color="white"
      height="80px"
    >
      <a class="title black--text mx-2" style="text-decoration: none;" :href="$router.resolve({ name: 'portal.index' }).href">
        <v-img
          :width="150"
          cover
          :src="info ? $utilities.publicPath(info.store_logo): ''"
        ></v-img>
        <!--
        <span>{{info ? info.name : 'Tienda'}}</span>
        -->
      </a>

      <v-spacer></v-spacer>

      <div class="hidden-md-and-up">
        <v-btn text small @click.stop="drawer = !drawer">
          <v-icon>mdi-menu</v-icon>
          Menu
        </v-btn>
      </div>
      <div class="hidden-sm-and-down">
        <v-text-field
          label="Busca Productos"
          append-icon="mdi-magnify"
          v-model="q"
          outlined
          hide-details
          class="d-inline-block"
          @keydown.enter="onTextSearch"
          @click:append="onTextSearch"
          dense
        ></v-text-field>

        <!--v-btn text small :href="$router.resolve({ name: 'portal.index' }).href">
          <v-icon left>mdi-home</v-icon>
          Inicio
        </v-btn-->
        <v-btn text small :href="$router.resolve({ name: 'portal.products.search' }).href">
          <!--v-icon left>mdi-archive</v-icon-->
          Productos
        </v-btn>
        <v-btn text small v-if="info ? info.can_sell_gift_cards: false" :href="$router.resolve({ name: 'portal.giftCards' }).href">
          <!--v-icon left>mdi-gift</v-icon-->
          Tarjetas Regalo
        </v-btn>
        <v-btn text small v-if="info ? info.show_online_booking_link : false" :href="info.online_booking_link">
          <!--v-icon left>mdi-calendar-check-outline</v-icon-->
          Agendar Cita
        </v-btn>
        <v-btn text small :href="$router.resolve({ name: 'portal.contact' }).href">
          <!--v-icon left>mdi-contacts</v-icon-->
          Contacto
        </v-btn>
        <v-badge
          bordered
          overlap
          :content="`${cart.items.length}`"
        >
          <v-btn icon @click="goToCart"><v-icon>mdi-cart</v-icon></v-btn>
        </v-badge>
      </div>
    </v-app-bar>

    <router-view></router-view>

    <v-bottom-navigation
      fixed
      grow
      background-color="white"
      height="60px"
    >
      <v-btn height="100%" v-if="info ? info.show_online_booking_link : false" :href="info.online_booking_link">
        <span class="subtitle-1">Agendar Cita</span>
        <v-icon>mdi-calendar-check-outline</v-icon>
      </v-btn>

      <v-btn height="100%" v-if="info ? info.can_sell_gift_cards: false" :href="$router.resolve({ name: 'portal.giftCards' }).href">
        <span class="subtitle-1">Tarjetas Regalo</span>
        <v-icon>mdi-gift</v-icon>
      </v-btn>

      <v-btn height="100%" :href="$router.resolve({ name: 'portal.products.search' }).href">
        <span class="subtitle-1">Productos</span>
        <v-icon>mdi-archive</v-icon>
      </v-btn>

      <v-btn height="100%" @click="goToCart()">
        <span class="subtitle-1">Carrito</span>
        <v-badge
          :content="`${cart.items.length}`"
          class="mr-4"
        >
          <v-icon>mdi-cart</v-icon>
        </v-badge>
      </v-btn>
    </v-bottom-navigation>

    <v-navigation-drawer
      v-model="drawer"
      app
      temporary
      right
      width="300"
      id="drawer"
    >
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-img
              contain
              max-height="70px"
              :src="info ? $utilities.publicPath(info.store_logo) : ''"
            ></v-img>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn text small @click.stop="drawer = !drawer">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list
        dense
        nav
      >
        <v-list-item>
          <v-text-field
            label="Busca Productos"
            append-icon="mdi-magnify"
            v-model="q"
            outlined
            hide-details
            class="d-inline-block"
            @keydown.enter="onTextSearch"
            @click:append="onTextSearch"
            dense
          ></v-text-field>
        </v-list-item>
        <v-list-item>
          <v-btn class="justify-start" block text small :href="$router.resolve({ name: 'portal.index' }).href">
            <v-icon left>mdi-home</v-icon>
            Inicio
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn class="justify-start" block text small :href="$router.resolve({ name: 'portal.products.search' }).href">
            <v-icon left>mdi-archive</v-icon>
            Productos
          </v-btn>
        </v-list-item>
        <v-list-item v-if="info ? info.can_sell_gift_cards: false">
          <v-btn class="justify-start" block text small :href="$router.resolve({ name: 'portal.giftCards' }).href">
            <v-icon left>mdi-gift</v-icon>
            Tarjetas Regalo
          </v-btn>
        </v-list-item>
        <v-list-item v-if="info ? info.show_online_booking_link : false">
          <v-btn class="justify-start" block text small :href="info.online_booking_link">
            <v-icon left>mdi-calendar-check-outline</v-icon>
            Agendar Cita
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn class="justify-start" block text small :href="$router.resolve({ name: 'portal.contact' }).href">
            <v-icon left>mdi-contacts</v-icon>
            Contacto
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn class="justify-start" block text small @click="goToCart">
            <v-badge
              bordered
              :content="`${cart.items.length}`"
            >
              <v-icon left>mdi-cart</v-icon>
            </v-badge>
            Carrito
          </v-btn>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-btn
      dark
      fab
      bottom
      fixed
      right
      color="green"
      style="bottom: 70px;"
      @click="openWhatsapp"
      v-if="info ? info.show_whatsapp_button : false"
    >
      <v-icon>mdi-whatsapp</v-icon>
    </v-btn>

    <v-footer dark padless style="margin-bottom: 60px;">
      <v-row>
        <v-col cols="12" sm="12" md="4" lg="4">
          <v-card
            dark
            class="white--text"
          >
            <v-card-title class="text-center white--text pt-0">Servicio al Cliente</v-card-title>

            <v-card-text class="text-left" v-if="info">
              <v-list>
                <v-list-item>
                  <v-list-item-icon><v-icon>mdi-city</v-icon></v-list-item-icon>
                  <v-list-item-content>{{ info.name }}</v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon><v-icon>mdi-email</v-icon></v-list-item-icon>
                  <v-list-item-content>email: {{ info.email }}</v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon><v-icon>mdi-map-marker</v-icon></v-list-item-icon>
                  <v-list-item-content>Dirección: {{ info.location_address }}</v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon><v-icon>mdi-cellphone</v-icon></v-list-item-icon>
                  <v-list-item-content>Celular: {{ info.contact_cellphone }}</v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon><v-icon>mdi-phone</v-icon></v-list-item-icon>
                  <v-list-item-content>Telefono: {{ info.contact_phone }}</v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="4">
          <v-card
            dark
            class="white--text"
          >
            <v-card-title class="white--text pt-0">Síguenos</v-card-title>

            <v-card-text v-if="info">
              <v-list>
                <v-list-item v-if="info.url_website" :href="info.url_website" target="blank">
                  <v-list-item-icon><v-icon>mdi-cloud</v-icon></v-list-item-icon>
                  <v-list-item-content>Pagina Web</v-list-item-content>
                </v-list-item>

                <v-list-item v-if="info.url_facebook" :href="info.url_facebook" target="blank">
                  <v-list-item-icon><v-icon>mdi-facebook</v-icon></v-list-item-icon>
                  <v-list-item-content>Facebook</v-list-item-content>
                </v-list-item>

                <v-list-item v-if="info.url_instagram" :href="info.url_instagram" target="blank">
                  <v-list-item-icon><v-icon>mdi-instagram</v-icon></v-list-item-icon>
                  <v-list-item-content>Instagram</v-list-item-content>
                </v-list-item>

                <v-list-item v-if="info.url_youtube" :href="info.url_youtube" target="blank">
                  <v-list-item-icon><v-icon>mdi-youtube</v-icon></v-list-item-icon>
                  <v-list-item-content>Youtube</v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
          <v-card
            dark
            class="white--text"
          >
            <v-card-title class="white--text pt-0">Metodos de Pago</v-card-title>

            <v-card-text>
              <v-avatar size="70" class="pr-3">
                <v-img contain max-height="100%" src="/assets/app/static/online_payments/mercadopago.png"></v-img>
              </v-avatar>

              <!--
              <br>

              <v-avatar size="70" class="pr-3">
                <v-img contain max-height="100%" src="/assets/app/static/online_payments/payu.png"></v-img>
              </v-avatar>

              <br>

              <v-avatar size="70" class="pr-3">
                <v-img contain max-height="100%" src="/assets/app/static/online_payments/epayco.png"></v-img>
              </v-avatar>
              -->
            </v-card-text>

            <div class="d-flex align-items-center justify-center">
              <span class="title font-weight-light grey--text text--lighten-1 mr-3">
                Compra Segura
              </span>
              <span id="siteseal" class="siteseal-container"></span>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="4">
          <v-card
            dark
            class="white--text text-center"
          >
            <v-card-title class="white--text pt-0">Legal</v-card-title>

            <v-card-text v-if="info">
              <v-list dense>
                <v-list-item :href="$router.resolve({ name: 'portal.legal', query: { q: 'terms_and_conditions' } }).href">
                  <v-list-item-icon><v-icon>mdi-file-outline</v-icon></v-list-item-icon>
                  <v-list-item-content>Términos y Condiciones</v-list-item-content>
                </v-list-item>
                <v-list-item :href="$router.resolve({ name: 'portal.legal', query: { q: 'gift_card_policies' } }).href">
                  <v-list-item-icon><v-icon>mdi-file-outline</v-icon></v-list-item-icon>
                  <v-list-item-content>Políticas de tarjetas regalos</v-list-item-content>
                </v-list-item>
                <v-list-item :href="$router.resolve({ name: 'portal.legal', query: { q: 'payment_policies' } }).href">
                  <v-list-item-icon><v-icon>mdi-file-outline</v-icon></v-list-item-icon>
                  <v-list-item-content>Políticas de pago</v-list-item-content>
                </v-list-item>
                <v-list-item :href="$router.resolve({ name: 'portal.legal', query: { q: 'return_policy' } }).href">
                  <v-list-item-icon><v-icon>mdi-file-outline</v-icon></v-list-item-icon>
                  <v-list-item-content>Políticas de devolución</v-list-item-content>
                </v-list-item>
                <v-list-item :href="$router.resolve({ name: 'portal.legal', query: { q: 'shipping_policies' } }).href">
                  <v-list-item-icon><v-icon>mdi-file-outline</v-icon></v-list-item-icon>
                  <v-list-item-content>Políticas de Envío</v-list-item-content>
                </v-list-item>
                <v-list-item :href="$router.resolve({ name: 'portal.legal', query: { q: 'data_processing_policies' } }).href">
                  <v-list-item-icon><v-icon>mdi-file-outline</v-icon></v-list-item-icon>
                  <v-list-item-content>Políticas de tratamiento de datos</v-list-item-content>
                </v-list-item>
                <v-list-item :href="$router.resolve({ name: 'portal.legal', query: { q: 'warranty_policy' } }).href">
                  <v-list-item-icon><v-icon>mdi-file-outline</v-icon></v-list-item-icon>
                  <v-list-item-content>Política de garantía</v-list-item-content>
                </v-list-item>
                <v-list-item :href="$router.resolve({ name: 'portal.legal', query: { q: 'returns_retracto_policy' } }).href">
                  <v-list-item-icon><v-icon>mdi-file-outline</v-icon></v-list-item-icon>
                  <v-list-item-content>Política de devoluciones, retracto</v-list-item-content>
                </v-list-item>
                <v-list-item :href="$router.resolve({ name: 'portal.legal', query: { q: 'exchange_policy' } }).href">
                  <v-list-item-icon><v-icon>mdi-file-outline</v-icon></v-list-item-icon>
                  <v-list-item-content>Política de cambio</v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="12">
          <div class="title font-weight-light grey--text text--lighten-1 text-center">
            &copy; {{ (new Date()).getFullYear() }} - Lizto
          </div>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import { setStoreSlug } from '@/api/utils'

export default {
  data () {
    return {
      drawer: false,
      q: '',
      scriptSealAdded: false
    }
  },
  methods: {
    onTextSearch () {
      if (this.q) {
        const url = this.$router.resolve({ name: 'portal.products.search', query: { q: this.q } }).href
        window.location.assign(url)
      }
    },
    goToCart () {
      const url = this.$router.resolve({ name: 'cart' }).href

      window.location.assign(url)
    },
    openWhatsapp () {
      const cellphone = `${this.info.contact_cellphone_indicator}${this.info.contact_cellphone}`
      const url = `https://api.whatsapp.com/send?phone=${cellphone}`
      window.open(url)
    },
    changeTitle (title) {
      document.title = `Tienda ${title}`
    },
    changeFavicon (src) {
      const link = document.createElement('link')
      const oldLink = document.getElementById('default-favicon')

      link.id = 'default-favicon'
      link.rel = 'icon'
      link.href = src

      if (oldLink) {
        document.head.removeChild(oldLink)
      }
      document.head.appendChild(link)
    },
    appendScriptSeal () {
      if (this.scriptSealAdded) { return }

      this.scriptSealAdded = true

      const sealId = 'Ewrm8mLflh2Eh802I9csRUwxqWOTLZ2Zaj9D5eUCIKUxjPhqF6mSSfIV39Lq'

      const sealScript = document.createElement('script')

      sealScript.setAttribute('src', `https://seal.godaddy.com/getSeal?sealID=${sealId}`)

      document.head.appendChild(sealScript)
    }
  },
  computed: {
    ...mapState('cart', {
      cart: state => state.cart,
      cartIsLoading: state => state.cartIsLoading
    }),
    ...mapState('general', {
      info: state => state.info,
      loadingPage: state => state.pageIsLoading,
      snackbar: state => state.snackbar
    })
  },
  created () {
    setStoreSlug(this.$route.params.portalId)

    this.$store.dispatch('cart/loadCart')
    this.$store.dispatch('general/loadInfo', this.$route.params.portalId)
      .then(res => {
        this.changeTitle(res.name)
        this.changeFavicon(this.$utilities.publicPath(res.store_logo))
      })
      // .catch(res => {})
  },
  mounted () {
    this.appendScriptSeal()
  }
}
</script>
